<template>
  <div class="app flex-row align-items-center">
    <div class="container" v-if="p_supportData.show">
      <b-row class="justify-content-center">
        <b-col md="12">
          <p v-if="p_supportData.edit_eligible">
            <b-row>
              <b-col cols="4">
                <strong>
                  <i class="fa fa-pencil"></i>
                </strong>
                {{ StoreLangTranslation.data['subject_type'][StoreLang] }}
              </b-col>
              <b-col cols="8"></b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="p_supportData.subject">
                  <option v-for="(sup, sup_ind) in d_supportList" :value="sup.value">
                    {{ sup.translation[StoreLang] }}
                  </option>
                </b-form-select>
              </b-col>
            </b-row>
          </p>

          <p>
            <b-row>
              <b-col cols="4">
                <strong>
                  <i class="fa fa-pencil"></i>
                </strong>
                {{ StoreLangTranslation.data['caption'][StoreLang] }}
              </b-col>
              <b-col cols="8"></b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <template v-if="p_supportData.edit_eligible">
                  <b-textarea v-model="p_supportData.operation_name" rows="3" :maxlength="p_supportData.maximum_characters_name"></b-textarea>
                </template>
                <template v-else>
                  {{ p_supportData.operation_name }}
                </template>
              </b-col>
            </b-row>
          </p>

          <p>
            <b-row>
              <b-col cols="4">
                <strong>
                  <i class="fa fa-pencil"></i>
                </strong>
                {{ StoreLangTranslation.data['explanation'][StoreLang] }}
              </b-col>
              <b-col cols="8"></b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <template v-if="p_supportData.edit_eligible">
                  <b-textarea id="opeation_note" v-model="p_supportData.operation_note" rows="10" :maxlength="p_supportData.maximum_characters_note"></b-textarea>
                </template>
                <template v-else>
                  {{ p_supportData.operation_note }}
                </template>
              </b-col>
            </b-row>
          </p>


          <template v-if="!p_supportData.edit_eligible">
            <p>
              <b-row>
                <b-col cols="12">
                  <strong>
                    <i class="fa fa-pencil"></i>
                  </strong>
                  {{ StoreLangTranslation.data['support_operation_note_additional'][StoreLang] }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-textarea v-model="p_supportData.operation_note_additional" rows="10"></b-form-textarea>
                </b-col>
              </b-row>
            </p>
          </template>


          <template v-if="!d_user">
            <p>
              <b-row>
                <b-col cols="12">
                  <strong>
                    <i class="fa fa-pencil"></i>
                  </strong>
                  {{ StoreLangTranslation.data['mail'][StoreLang] }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-input type="text" class="form-control-warning" id="mail" key="mail" :formatter="f_toLowerCase(p_supportData.mail, 'mail')" v-model="p_supportData.mail" :state="f_emailRegexCheck(p_supportData.mail)"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>
                    {{ StoreLangTranslation.data['please_write_true_mail'][StoreLang] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </p>

            <p>
              <b-row style="text-align: center;">
                <b-col cols="12">
                  <div id="captcha"></div>
                </b-col>
              </b-row>
              <b-row style="text-align: center;">
                <b-col cols="12">
                  <b-button size="sm" @click="f_createCaptcha()" variant="white"><i class="fa fa-edit" style="color: #45dc45;"></i>&nbsp; {{ StoreLangTranslation.data['change'][StoreLang] }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                    <b-form-input id="captchaCode" type="text" class="form-control-warning" required v-model="d_captchaWrittenCode" :state="d_captchaWrittenCode === d_captchaCode"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      {{ StoreLangTranslation.data['write_captcha_code'][StoreLang] }}
                    </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </p>

            <p v-if="d_mailApproveCodeSended === 'yes'">
              <b-row>
                <b-col cols="12">
                  <strong>
                    <i class="fa fa-pencil"></i>
                  </strong>
                  {{ StoreLangTranslation.data['mail_approve_code'][StoreLang] }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-input type="text" class="form-control-warning" id="mail_approve_code" key="mail_approve_code" v-model="d_mailApproveCode" :state="d_mailApproveCode.length === 6"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>
                    {{ StoreLangTranslation.data['please_write_mail_approve_code'][StoreLang] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </p>

          </template>

          <b-row>
            <b-col cols="8">
            </b-col>
            <b-col cols="4">
              <template v-if="d_supportSaveLoading">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </template>
              <template v-else>
                <b-button :variant="f_controlSaveEligible() ? 'outline-success' : 'outline-danger'" @click="f_supportNew()" size="lg" style="width: 100%;" :disabled="!f_controlSaveEligible()">
                    <i class="fa fa-send-o"></i>
                    {{ StoreLangTranslation.data['send'][StoreLang] }}
                  </b-button>
              </template>
            </b-col>
          </b-row>
          
          <!--
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-search"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
              <b-input-group-append>
                <b-button variant="info">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ClsRoute } from '@/wam/modules/route';
import { WisdomRegex } from '@/public/WisdomRegex';
import { data as support_list } from '@/wam/options/support_list';
import { ServiceFunctions } from '@/wam/modules/service';
import { mapGetters } from 'vuex';
export default {
  name: 'SupportScreen',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  props: {
    p_supportData: {
      type: Object,
      required: true
    },
    f_userSupportList: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      d_supportSaveLoading: false,
      d_mailApproveCode: '',
      d_mailApproveCodeSended: '',
      d_supportList: support_list,
      d_user: '',
      d_captchaWrittenCode: '',
      d_captchaCode: '',
    }
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    if (!this.d_user) {
      this.f_createCaptcha();
    }
  },
  methods: {
    f_formatText: function (value, param) {
      this.p_supportData[param] = value.substring(0, this.p_supportData.maximum_characters);
    },
    f_emailRegexCheck: function (email) {
      let status = WisdomRegex.email(email).status;
      this.p_supportData.mail_status = status;
      return status;
    },
    f_toLowerCase: function (value, param) {
      this.p_supportData[param] = value.toLowerCase();
    },
    f_controlSaveEligible: function() {
      let save_eligible = true;
      if (this.d_user) {
        if (this.p_supportData.operation_name.length < 5) {
          save_eligible = false;
        } else if (this.p_supportData.operation_note.length < 10) {
          save_eligible = false;
        }
      } else {
        if (this.p_supportData.operation_name.length < 5) {
          save_eligible = false;
        } else if (this.p_supportData.operation_note.length < 10) {
          save_eligible = false;
        } else if (this.d_captchaWrittenCode !== this.d_captchaCode) {
          save_eligible = false;
        } else if (!this.p_supportData.mail_status) {
          save_eligible = false;
        }
      }
      return save_eligible;
    },
    f_createCaptcha: function() {
      this.d_captchaWrittenCode = '';
      this.d_captchaCode = '';
      //clear the contents of captcha div first 
      document.getElementById('captcha').innerHTML = "";
      let charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
      let lengthOtp = 6;
      let captcha = [];
      for (let i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) == -1)
          captcha.push(charsArray[index]);
        else i--;
      }
      let canv = document.createElement("canvas");
      canv.id = "captcha";
      canv.width = 100;
      canv.height = 50;
      let ctx = canv.getContext("2d");
      ctx.font = "25px Georgia";
      ctx.strokeText(captcha.join(""), 0, 30);
      //storing captcha so that can validate you can save it somewhere else according to your specific requirements
      this.d_captchaCode = captcha.join("");
      // console.log('this.d_captchaCode : ', this.d_captchaCode);
      document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
      this.$forceUpdate();
    },
    f_goCustomerService: function () {
      this.$router.push({ path: ClsRoute.f_getRoutePath('customer_service', this.StoreLang), query: {} });
    },
    f_supportNew: function () {
      if (confirm(this.StoreLangTranslation.data['do_you_confirm_the_process'][this.StoreLang])) {
        if (this.d_user) {
          let query = 'user_client_id=' + this.d_user.username;
          if (this.p_supportData.user_shop_bundle_client_id) {
            query += '&user_shop_bundle_client_id=' + this.p_supportData.user_shop_bundle_client_id;
          }
          let data = {
            'subject': this.p_supportData.subject,
            'operation_name': this.p_supportData.operation_name,
            'operation_note': this.p_supportData.operation_note + '\n' + this.p_supportData.operation_note_additional,
          };
          this.d_supportSaveLoading = true;
          ServiceFunctions.user_support_new(query, data)
            .then(resp => {
              this.d_supportSaveLoading = false;
              if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                // this.f_goCustomerService();
                this.p_supportData.show = false;
                if (this.f_userSupportList) {
                  this.f_userSupportList();
                }
              } else {
                alert(resp.data.status_code + ' = ' + resp.data.status_message);
              }
              this.$forceUpdate();
            });
        } else {
          let query = '';
          if (this.d_mailApproveCode && this.d_mailApproveCodeSended === 'yes') {
            query += 'mail_approve_code=' + this.d_mailApproveCode;
          }
          let data = {
            'subject': this.p_supportData.subject,
            'mail': this.p_supportData.mail,
            'operation_name': this.p_supportData.operation_name,
            'operation_note': this.p_supportData.operation_note + '\n' + this.p_supportData.operation_note_additional,
          };
          this.d_supportSaveLoading = true;
          ServiceFunctions.mail_support_record(query, data)
            .then(resp => {
              this.d_supportSaveLoading = false;
              if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                if (resp.data.mail_approve_code_sended && resp.data.mail_approve_code_sended === 'yes') {
                  this.d_mailApproveCodeSended = 'yes';
                } else if (resp.data.mail_approve_code_check_success && resp.data.mail_approve_code_check_success === 'yes') {
                  this.d_mailApproveCodeSended = '';
                  this.d_mailApproveCode = '';
                  this.p_supportData.show = false;
                  alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
                  // this.f_goCustomerService();
                  if (this.f_userSupportList) {
                    this.f_userSupportList();
                  }
                }
              } else {
                alert(resp.data.status_code + ' = ' + resp.data.status_message);
              }
              this.$forceUpdate();
            });
        }
      }
    },
  }
}
</script>
