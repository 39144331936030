class WisdomRegex {
  static regex1 = function(value) {
    // general info //
    // this regex is doing some changes to 'value'
    // 'value' needs at least 1 char for beginning regex
    // regex info //
    // 'value' can contain only 'a-z 0-9 _ -' chars (lowercase letters, numbers, underscore, hypen)
    // 'value' cannot contain consecutive '-' and/or '_' chars
    // status info //
    // if 'value' is empty, then status is false
    // if 'value' is not empty but its last char is underscore or hypen, then status is false
    // else, then status is true
    // example i/o //
    // input: -____---ThiS is Jus--------------TT a ____'''2' new ---- ____ test ,.$.:---
    // output: {'value': 'thisisjus-tta_2new-test-', 'status': false}
    let status = false;
    if (value.length > 0) {
      value = value.toLowerCase();
      value = value.replace(/[^a-z0-9_-]+/ig, '');
      value = value.replace(/[-]+/ig, '-');
      value = value.replace(/[_]+/ig, '_');
      value = value.replace(/(-_)+/ig, '-');
      value = value.replace(/(_-)+/ig, '_');
      value = value.replace(/^[-_]/ig, '');
      if (['_', '-'].indexOf(value.charAt(value.length - 1)) === -1) {
        status = true;
      }
    }
    return { 'value': value, 'status': status };
  };
  static regex2 = function(value) {
    // general info //
    // this regex is doing some changes to 'value'
    // 'value' needs at least 1 char for beginning regex
    // regex info //
    // 'value' cannot contain '[]{};' chars
    // input: asdasd[asdasdasd]asdasd]asdasd{asdasdasd}
    // output: {'value': 'asdasdasdasdasdasdasdasdasdasdasdasd', 'status': false}
    let status = false;
    if (value.length > 0) {
      value = value.replace(/[\[\]\{\}\;]+/ig, '');
      status = true;
    }
    return { 'value': value, 'status': status };
  };
  static email = function(value) {
    // general info //
    // this regex is used for email validation
    // regex info //

    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    let status = false;

    if (value.length > 0) {
      reg.test(value) ? status = true : status = false;
    }

    return { 'value': value, 'status': status };
  };
  static phone = function(value) {
    // general info //
    // this regex is used for phone validation
    // regex info //

    let reg = /^[0-9]+$/;

    let status = false;

    if (value.length > 0) {
      reg.test(value) ? status = true : status = false;
    }

    return { 'value': value, 'status': status };
  };
  static password = function(value) {
    // general info //
    // this regex is used for email validation
    // regex info //

    let reg = /^[a-zA-Z0-9$@$!%*?&#^\-_. +]+$/;

    let status = false;

    if (value.length > 0) {
      reg.test(value) ? status = true : status = false;
    }

    return { 'value': value, 'status': status };
  };
};

export { WisdomRegex };
