var data = [
    {
        'translation': {'en': 'general', 'tr': 'genel'},
        'value': 'General',
    },
    {
        'translation': {'en': 'product support', 'tr': 'ürün destek'},
        'value': 'ProductSupport',
    },
    {
        'translation': {'en': 'product order', 'tr': 'ürün sipariş'},
        'value': 'ProductOrder',
    },
];


export { data };